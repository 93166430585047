.start-session {
  .MuiFab-root {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
  }
  .actions {
    margin: 0.5rem 0rem;
    display: flex;
    justify-content: space-between;
  }

  .MuiFormControl-root {
    margin: 0.5rem 0rem;
  }

  .MuiNativeSelect-select:not([multiple]) {
    option {
      background-color: var(--primary);
    }
  }
}
