.icon {
  width: 2.25rem;
}

.to-top {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  @media (max-width: 768px) {
    top: unset;
    left: unset;
    bottom: 0rem;
    right: 0rem;
  }
}

.to-bottom {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  @media (max-width: 768px) {
    top: unset;
    right: unset;
    bottom: 0rem;
    left: 0rem;
  }
}

[class^="to-"] {
  opacity: 0.7;
  filter: drop-shadow(0px 2px 3px black);
  z-index: 1;
  &:hover {
    opacity: 1;
  }
  & > button {
    min-width: unset;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none;
  }
}
.desktop {
  @media (max-width: 768px) {
    display: none;
  }
}
