.firebase-emulator-warning {
  display: none;
}

body {
  --sizing-scale: 0.7;
  --primary: #282c34;
  --secondary: #ffffff;
  // --secondary: #CAE5FF;
  --tertiary: #2e86ab;
  --tertiary: #89bbfe;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--secondary);
  will-change: background-color;
  transition: background-color ease .5s;
}

.shrink-noes {
  --sizing-scale: 0.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
