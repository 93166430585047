.game {
  --grid-gap: 0.125rem;
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
    "header header header header"
    "ctrls ctrls board board"
    "ctrls ctrls board board"
    "ctrls ctrls board board"
    "hand hand board board"
    "hand hand board board"
    "hand hand board board";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--grid-gap);
  background-color: transparent;
  padding: var(--grid-gap);
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  @media (min-width: 768px) {
    grid-template-areas:
      "header header header header"
      "board board board board"
      "board board board board"
      "hand hand ctrls ctrls"
      "hand hand ctrls ctrls"
      "hand hand ctrls ctrls"
      "hand hand ctrls ctrls";
  }
}

// testing
.grid-area {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  padding: 0rem 0.3rem;
  overflow: scroll;
  max-width: calc(100vw - var(--grid-gap) - var(--grid-gap));
  border-radius: 0.25rem;
}

.game * {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.header {
  grid-area: header;
  position: relative;
  display: flex;
}
.controls {
  grid-area: ctrls;
  scroll-snap-type: x mandatory;
  scroll-snap-align: center;
  overscroll-behavior-x: contain;
  & > * {
    scroll-snap-stop: always;
  }
}
.hand {
  grid-area: hand;
}
.board-container {
  padding: 0px;
  position: relative;
  grid-area: board;
  --rotation-factor: 90deg;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 768px) {
    --rotation-factor: 0deg;
    align-items: center;
    flex-direction: row;
  }
  & > div:nth-child(2) {
    will-change: box-shadow;
    transition: box-shadow ease 0.2s;
  }
}

.board-container.offset-right > div:nth-child(2) {
  box-shadow: inset -20px 0px 18px -24px black;
  @media (max-width: 768px) {
    box-shadow: inset 0px -20px 18px -24px black;
  }
}

.board-container.offset-left > div:nth-child(2) {
  box-shadow: inset 20px 0px 18px -24px black;
  @media (max-width: 768px) {
    box-shadow: inset 0px 20px 18px -24px black;
  }
}

.board-container.offset-left.offset-right > div:nth-child(2) {
  box-shadow: inset 20px 0px 18px -24px black, inset -20px 0px 18px -24px black;
  @media (max-width: 768px) {
    box-shadow: inset 0px -20px 18px -24px black,
      inset 0px 20px 18px -24px black;
  }
}
.board-scroller {
  display: flex;
  position: relative;
  overflow: scroll;
  align-self: stretch;
}

.status {
  position: absolute;
  top: 0px;
  width: 100%;
}

.game-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  .right-side {
    flex-grow: 1;
  }
}

.game-timeline {
  list-style-type: decimal;
  --sizing-scale: 0.275;
  text-align: start;
  padding: 0px;
  li {
    display: list-item;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    counter-increment: mycount;
  }
  li.current {
    & span::before {
      display: inline-block;
      content: ">";
    }
    color: #90caf9;
  }
}
