.seat {
  border-radius: 16rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  margin: 5px;
  display: inline-block;
  padding: 0.125rem;
  cursor: pointer;
  overflow: hidden;
  width: 3rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (min-width: 768px) {
    width: 5rem;
  }
}

.seat:hover {
  background-color: #000;
  color: #fff;
}

.seat.taken {
  background-color: #ccc;
  color: #000;
  cursor: not-allowed;
}

.seat.taken:hover {
  background-color: #ccc;
  color: #000;
}

.seats {
  padding: 0px;
  margin: 0px;
}
