.content {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: min-content;
}

.login-form {
  padding: 1rem;
  border-radius: 2.5rem;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
  & > *:not(.separator) {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}

.form-header {
  text-align: center;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  button {
    flex-basis: 40%;
  }
}
.separator {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0px;
}
.separator::before, .separator::after {
  content: "";
  height: 1px;
  background-color: currentColor;
  margin-inline: 1rem;
  flex-basis: 100%;
}
