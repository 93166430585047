

.domino {
  width: calc(50px * var(--sizing-scale));
  height: calc(101px * var(--sizing-scale));
  display: inline-flex;
  flex-shrink: 0;
  flex-direction: column;
  background-color: white;
  position: relative;
  margin: 0.075rem;
  border-radius: 0.25rem;
}
// * {
//   transition: all .2s;
// }
.highlight {
  outline: solid 2px black;
  box-shadow: 0px 1px 22px 1px gray;
  transform: scale(1.05);
  z-index: 2;
  transition: all .1s;
}

.domino.rotate-90 {
  transform: rotate(calc(90deg + var(--rotation-factor, 0deg)));
  margin: 0px calc(25px * var(--sizing-scale));
}

.domino.rotate-0 {
  transform: rotate(calc(0deg + var(--rotation-factor, 0deg)));
  // couldnt escape the media query this time
  @media (max-width: 768px) {
    margin: calc(-25px * var(--sizing-scale)) 0px;
  }
}

.domino.rotate--90 {
  transform: rotate(calc(-90deg + var(--rotation-factor, 0deg)));
  margin: 0px calc(25px * var(--sizing-scale));
}

.domino-side {
  flex-basis: calc(50px * var(--sizing-scale));
  display: flex;
  flex-wrap: wrap;
  padding: calc(0.25rem * var(--sizing-scale)) calc(0.375rem * var(--sizing-scale));
}

.domino-gone {
  background-color: grey;
  cursor: not-allowed;
}

.dot {
  width: calc(10px * var(--sizing-scale));
  height: calc(10px * var(--sizing-scale));
  border-radius: 50%;
  margin: calc(0.125rem * var(--sizing-scale));
  background-color: black;
  overflow: hidden;
  box-shadow: inset 2px 2px 3px rgba(200, 200, 200, 0.6);
}

.side-seperator {
  width: 100%;
  height: 1px;
  background-color: black;
  position: absolute;
  top: 50%;
}

.side-seperator::after {
  content: "";
  width: 2px;
  height: 2px;
  background-color: silver;
  position: absolute;
  border-radius: 50%;
  top: -0.5px;
  left: 50%;
}

div[class^="dot-"] {
  display: flex;
}

.dot-1:nth-child(1) {
  align-self: center;
  flex-basis: 100%;
  justify-content: center;
}

.dot-2 {
  padding: calc(0.25rem * var(--sizing-scale));
}

.dot-2:nth-child(1) {
  align-self: flex-start;
  flex-basis: 100%;
  justify-content: flex-end;
}

.dot-2:nth-child(2) {
  align-self: flex-end;
  flex-basis: 100%;
  justify-content: flex-start;
}

.dot-3:nth-child(1) {
  align-self: flex-start;
  flex-basis: 100%;
  justify-content: flex-end;
}

.dot-3:nth-child(2) {
  align-self: center;
  flex-basis: 100%;
  justify-content: center;
}

.dot-3:nth-child(3) {
  align-self: flex-end;
  flex-basis: 100%;
  justify-content: flex-start;
}

.dot-4,
.dot-5 {
  flex-basis: 50%;
}

.dot-4:nth-child(odd),
.dot-5:nth-child(1),
.dot-5:nth-child(4) {
  justify-content: flex-start;
}

.dot-4:nth-child(even),
.dot-5:nth-child(2),
.dot-5:nth-child(5) {
  justify-content: flex-end;
}

.dot-4:nth-child(1),
.dot-4:nth-child(2),
.dot-5:nth-child(1),
.dot-5:nth-child(2) {
  align-self: flex-start;
}

.dot-4:nth-child(3),
.dot-4:nth-child(4),
.dot-5:nth-child(4),
.dot-5:nth-child(5) {
  align-self: flex-end;
}

.dot-5:nth-child(3) {
  flex-basis: 100%;
  align-self: center;
  justify-content: center;
}

.dot-6 {
  flex-basis: 50%;
  display: flex;
}

.dot-6:nth-child(odd) {
  justify-content: flex-start;
}

.dot-6:nth-child(even) {
  justify-content: flex-end;
}
