.session-conainer {
  flex-basis: 100%;
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  .MuiList-root {
    max-width: unset;
  }
  @media (min-width: 768px) {
    // grid-template-columns: 1fr 1fr;
    // grid-template-rows: auto;
    // grid-gap: 1rem;
    padding: 1rem 10rem;
  }
}

.session-forms {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.form-header {
  flex-basis: 100%;
}

.session-heading {
  margin: 0.25rem;
  line-height: 1;
}
