.board {
  // this is so the box shadow from the scroller will be on top of the dominoes
  display: inline-flex;
  margin: 0 auto;
  max-width: 100%;
  // overflow-x: scroll;
  align-items: center;
  @media (max-width: 768px) {
    min-width: 125px;
    max-width: calc(125px * var(--sizing-scale));
    // overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    margin: 0 auto;
    height: min-content;
  }
}

.bg {
  // when the board doesnt have sticky ends yet it needs to be clickable so the user can play, so we only add
  // this property after the board gets its sticky ends 
  z-index: -1;
}

