.game-chat {
  display: grid;
  grid-template-areas:
    "messages"
    "box";
  grid-template-rows: 1fr auto;
  min-width: 100%;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
}

.messages {
  grid-area: messages;
  overflow: scroll;
  max-height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.message {
  text-align: left;
  background-color: #ffffff19; //var(--primary);
  margin-block: 0.4rem;
  padding: 0.3rem;
  width: max-content;
  max-width: 80%;
  
  &.me {
    margin-left: auto;
  }

  & .sender {
    display: block;
    font-weight: bold;
    color: var(--tertiary);
  }

  &.group {
    margin-block: 1px;
    padding-block: .1rem;
  }

  &.group-start {
    margin-block-start: 0.4rem;
  }

  @container (max-width: 450px) {
    & {
      // background-color: red;
      font-size: 0.8rem;
    }
  }
}
